<template>
    <div>
        <vue-tabs
        active-tab-color="#242424"
        active-text-color="white"
        type="pills"
        v-model="tabIndex"
        >
            <v-tab title="Sync ad Account">
                <div class="onboarding">
                    <div class="onboarding__counter">
                        <button type="button" class="onboarding__count onboarding__count--active"><img src="/img/svg/success.svg" alt="success" /></button>
                        <button type="button" class="onboarding__count onboarding__count--active">2</button>
                        <button type="button" class="onboarding__count">3</button>
                        <button type="button" class="onboarding__count">4</button>
                    </div>
                    <div v-if="!this.$store.getters.hasSyncedAccs" class="onboarding__content">
                        Please connect your facebook on the integration tab
                    </div>
                    <div v-else class="onboarding__content">
                        <h3 v-if="isLoading" class="onboarding__title">Please wait while we sync your accounts</h3>
                        <div v-else class="onboarding__info text-center">
                            <h3 class="onboarding__title">Select ad accounts to sync</h3>
                            <p>Please select the ad accounts</p>
                            <p>you would like to synchronise and manage.</p>
                        </div>

                        <b-progress v-if="isLoading" variant="dark" :value="progressValue" max="100" animated></b-progress>
                            <loader :isLoading="isLoading"></loader>
                                <div v-show="!isLoading">
                                    <div class="d-flex onboarding__filter-wrap">
                                        <div class="input-search-accs-first d-flex justify-content-end align-items-center"></div>
                                        <div class="input-wrap input-wrap--wide d-flex align-items-center">
                                            <button class="button button--small" @click="selectAll()">{{ selectedAccounts.length ? 'Unselect' : 'Select All' }}</button>
                                            <p class="m-2">Selected: {{ selectedAccounts.length }} of {{ addAccounts.length }}</p>
                                        </div>
                                    </div>
                                    <div class="d-flex onboarding__filter-wrap">
                                        <div class="input-search-accs-first d-flex justify-content-end align-items-center">#</div>
                                        <div class="input-wrap input-wrap--search input-wrap--wide">
                                            <input v-model="searchValue" class="text-input text-input--search text-input--search-wide" type="text" placeholder="Search by accounts" />
                                        </div>
                                    </div>
                                </div>
                            <ul class="onboarding__accounts-list" :class="{ 'conatiner-list-accounts':  addAccounts.length > 0 && !isLoading}">
                                <div v-if="addAccounts.length > 0">
                                    <div v-for="(addAccount, index) in addAccounts" :key="addAccount.id" class="d-flex mb-3">
                                        <div class="input-search-accs d-flex justify-content-end align-items-center">{{ index+1 }}</div>
                                        <div class="checkbox-img"></div>

                                        <li class="onboarding__accounts-item">
                                            <input v-model="selectedAccounts" :id="addAccount.id" type="checkbox" :value="addAccount.id" class="onboarding__accounts-checkbox" />
                                            <label :for="addAccount.id" class="onboarding__accounts-label">
                                            <div class="onboarding__accounts-name">
                                                <div class="onboarding__accounts-circle"></div>
                                                <p>{{ addAccount.name }}</p>
                                            </div>
                                        </label>
                                        <div class="onboarding__select-wrap onboarding__accounts-select-wrap opacity-0">
                                            <select class="onboarding__select onboarding__accounts-select d-none">
                                                <option value="">Condition</option>
                                                <option value="low">Low priority</option>
                                                <option value="middle">Middle priority</option>
                                                <option value="high">High priority</option>
                                            </select>
                                        </div>
                                        </li>
                                    </div>
                                </div>

                                <div v-if="notSynced" class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p>
                                            No ads accounts or not sync yet
                                        </p>
                                    </div>
                                    <div class="onboarding__button-wrap">
                                        <button class="button button--small" @click="reload()">Sync</button>
                                    </div>

                                </div>
                            </ul>
                            <div :class="{'opacity-0 pe-none': !selectedAccounts.length}" class="onboarding__button-wrap">
                                <a @click.prevent="syncSelected" class="button button--large" :href="thirdUrl">Continue</a>
                            </div>

                    </div>
                </div>
            </v-tab>

            <v-tab title="Profile">
                <profile></profile>
            </v-tab>

            <v-tab title="Integration">
                <Integration-step></Integration-step>
            </v-tab>
        </vue-tabs>
    </div>
</template>

<script>
import UrlService from '../services/UrlService'
import Loader from '../Loader.vue'
import debounce from 'lodash.debounce'
import _ from 'lodash'
import Profile from '../user/Profile.vue'
import IntegrationStep from './IntegrationStep.vue'

    export default {
        components: { Loader, Profile, IntegrationStep },
        data() {
            return {
                addAccounts: [],
                selectedAccounts: [],
                notSynced: false,
                isLoading: false,
                thirdUrl: UrlService.thirdStepUrl(),
                searchValue: '',
                tabIndex: new URLSearchParams(window.location.search).get('tab') ?? 0,
                progressValue: 0,
                intervalChecks: null
            }
        },
        async mounted() {

            this.isLoading = true
            const resp = await this.getMyAdsAccounts();

            if (this.addAccounts.length === 0 && this.$store.getters.hasSyncedAccs) {
                this.intervalChecks = setInterval(async function(){
                    if (this.addAccounts.length === 0) {
                        let respAccs = await this.getMyAdsAccounts();
                    }

                    this.progressValue += Math.floor(Math.random() * 2) + 1;

                }.bind(this), 5000);
            }

            const respSelected =  await this.getMyAdsSelectedAccounts();
        },
        methods:{
            async getMyAdsAccounts(filter = null){
                let response = ''
                if (filter) {
                    response = await UrlService.getFilteringAccount('?filter[name]=' + filter)
                } else {
                    response = await UrlService.getMyAdsAccounts()
                }

                this.addAccounts = response.data.data

                if(this.addAccounts.length === 0 && this.searchValue === ''){
                    this.notSynced = true
                }

                if (this.addAccounts.length != 0) {
                    this.isLoading = false
                }
            },
            async getMyAdsSelectedAccounts(){
                const responseSelected = await UrlService.getOnboardingSelectedAccounts().catch(err => {
                        return err
                    })

                    if (responseSelected.status === 200) {
                    this.selectedAccounts = _.map(responseSelected.data.data, 'id');

                    this.pagination = responseSelected.data.meta;
                    } else {
                        this.$store.commit('setDismissCountDown', 5)
                        this.$store.commit('setAlertText', responseSelected.response.data.message)
                        this.$store.commit('setVariantMessage', 'danger')
                    }
            },
            async syncSelected(){
                console.log(this.selectedAccounts.length)

                if (this.selectedAccounts.length > 50) {
                    this.$store.commit('setDismissCountDown', 5)
                    this.$store.commit('setAlertText', 'You can\'t choose more than 50 facebook accounts')
                    this.$store.commit('setVariantMessage', 'danger')
                    return;
                }

                let response = await UrlService.syncAccounts({id_accs: this.selectedAccounts}).catch(err => {
                    return err
                })

                if (response.status == 200) {
                    return window.location.href = this.thirdUrl
                }

                this.$store.commit('setDismissCountDown', 5)
                this.$store.commit('setAlertText', response.response.data.message)
                this.$store.commit('setVariantMessage', 'danger')
            },
            search(){
                this.getMyAdsAccounts(this.searchValue)
            },
            reload(){
                window.location.reload()
            },
            selectAll(){
                if (this.selectedAccounts.length) {
                    this.selectedAccounts = []
                }else{
                    this.addAccounts.map((account) => {
                        this.selectedAccounts.push(account.id)
                    })
                }
            }
        },
        watch:{
            "selectedAccounts": function(oldVal, newVal){
                this.$store.commit('setSelectedAccounts', this.selectedAccounts)
            },
            searchValue: debounce(async function(){
                this.search()
            }, 400),
            addAccounts: function autoRefresh() {
                if (this.addAccounts.length !== 0) {
                    clearInterval(this.intervalChecks)
                }
            }
        }
    }
</script>

<style scoped>
.input-search-accs{
    min-width: 20px;
}
.input-search-accs-first{
    min-width: 35px;
}
.conatiner-list-accounts{
    min-height: 286px;
}
</style>
